export const smothScrolling = () => {
    if (location.hash) { 
        if (location.hash[1] != '.') {
            smoothScrollingTo(location.hash);
        }
    }

    function smoothScrollingTo(target) {;
        var navBar = document.getElementById("site-navbar");
        var navBarHeight = 80;

        if (navBar) {
            navBarHeight = navBar.offsetHeight;
        }
        
        jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - navBarHeight + 2 }, 300);
    }

    jQuery('.menu-item.nav-item > a[href^="#"], a[href^="#"].smooth').on('click', function (event) {
        event.preventDefault();
        smoothScrollingTo(this.hash);
    });
}
