const scrollaJs = () => {
    (function ($) {
        'use strict';
        $(document).ready(function () {
    
            $('.animate').scrolla({
                once: true, // only once animation play on scroll
                mobile: true, // disable animation on mobiles
            });
        });
    }(jQuery));    
}

export { scrollaJs };
