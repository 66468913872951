export const swiperProfesionales = () => {
    new Swiper(".swiper-profesionales", {
                
        slidesPerView: 1.10,
        spaceBetween: 16,
        loop: true,
        centeredSlides: true,
        breakpoints: {
            768: {
                slidesPerView: 3.5,
                spaceBetween: 16,
            },
            992: {
                slidesPerView: 4.5,
            },
            1500: {
                slidesPerView: 5,
            }
        }
/*         pagination: {
          el: ".swiper-pagination"
        } */
    }); 
};

export const swiperCards = () => {
    new Swiper(".swiper-cards-menta", {
                
        slidesPerView: 1,
        spaceBetween: 32,
        loop: true,
        autoplay: true,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 0,
                loop: false,
                autoplay: false,
            }
        },
        pagination: {
          el: ".swiper-pagination"
        }
    }); 
};

export const swiperCounter = () => {
    new Swiper(".swiper-counter", {
                
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        autoplay: true,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 0,
                loop: false,
                autoplay: false,
            }
        },
        pagination: {
          el: ".swiper-pagination"
        }
    }); 
};

export const swiperItems = () => {
    new Swiper(".swiper-items", {
        slidesPerView: 1,
        effect: "fade",
        loop: true,
        autoplay: true,
        speed: 500,
        pagination: {
          el: ".swiper-pagination"
        }
    }); 
};
