import { fixedTop } from '../functions/fixed-top.js';
import { scrollaJs } from '../functions/scrolla.js';
import { swiperProfesionales, swiperCards, swiperItems, swiperCounter } from '../functions/swiperInit.js';
import { enableTooltip } from '../functions/tooltip.js';
import { show_countries } from '../functions/show-countries-photo.js';
import { counterJS } from '../functions/counter.js';
import { menuBtnFn } from '../functions/responsive-menu.js';
import { modalsCustom } from '../functions/modals.js';
import { floatingAnimate } from '../functions/floating.js';
import { smothScrolling } from '../functions/smooth-scrolling.js';


document.addEventListener("DOMContentLoaded", () => {
    fixedTop();
    scrollaJs();
    swiperProfesionales();
    swiperCards();
    enableTooltip();
    show_countries();
    swiperItems();
    swiperCounter();
    counterJS();
    menuBtnFn();
    modalsCustom();
    floatingAnimate();      
    smothScrolling();      

    jQuery(document).ready(function() {
        // Cuando se abre la modal
        jQuery('.modal').on('show.bs.modal', function () {
          jQuery('html, body').removeClass('overflow-x-hidden');
        });
      
        // Cuando se cierra la modal
        jQuery('.modal').on('hidden.bs.modal', function () {
          jQuery('html, body').addClass('overflow-x-hidden');
        });
      });
      
});

