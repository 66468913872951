export const show_countries = () => {
    const globos = document.querySelectorAll(".pais-globo");
    const paises = document.querySelectorAll(".pais");
    let currentIndex = 0;
    let intervalId = null; // Para guardar la referencia del setInterval

    function showNextGlobo() {
        // Ocultar todos los globos
        globos.forEach(globo => globo.style.opacity = 0);

        // Restablecer todos los elementos 'pais' a un relleno transparente
        paises.forEach(pais => pais.setAttribute('fill', 'transparent'));

        // Mostrar el siguiente globo
        globos[currentIndex].style.opacity = 1;

        // Aplicar el gradiente al siguiente país
        paises[currentIndex].setAttribute('fill', 'url(#gradient-fill)');

        // Incrementar el índice y reiniciar si es necesario
        currentIndex = (currentIndex + 1) % globos.length;
    }

    function startAnimation() {
        // Inicia el intervalo solo si no hay uno ya corriendo
        if (!intervalId) {
            intervalId = setInterval(showNextGlobo, 3000);
        }
    }

    function stopAnimation() {
        // Detiene el intervalo si hay uno corriendo
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;

            // Restaurar el estado de los elementos en modo escritorio
            globos.forEach(globo => {
                globo.style.opacity = ''; // Elimina el estilo inline de opacity
                globo.style.display = ''; // Restaura la visibilidad original
            });

            paises.forEach(pais => {
                pais.setAttribute('fill', ''); // Elimina el gradiente inline
            });
        }
    }

    function handleResize() {
        if (window.innerWidth <= 768) {
            startAnimation();  // Iniciar animación en móviles
        } else {
            stopAnimation();  // Detener animación y restaurar globos en escritorio
        }
    }

    // Agregar el listener para el evento resize
    window.addEventListener('resize', handleResize);

    // Llamar a la función al cargar la página para iniciar la animación si es necesario
    handleResize();
}
