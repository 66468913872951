const counterJS = () => {
	const counters = document.querySelectorAll(".item-value");
	const speed = 500;

	const animateCounter = (counter, targetValue) => {
		const data = +counter.innerText;
		const time = (targetValue - data) / speed;

		if (data < targetValue) {
			counter.innerText = Math.ceil(data + time);
			requestAnimationFrame(() => animateCounter(counter, targetValue));
		} else if (data > targetValue) {
			counter.innerText = Math.floor(data + time);
			requestAnimationFrame(() => animateCounter(counter, targetValue));
		} else {
			counter.innerText = targetValue;
			if (counter.getAttribute("numberTo") == targetValue) {
				// Llama a la función de fluctuación después de alcanzar el valor objetivo
				setTimeout(() => fluctuateCounter(counter), randomTime(5000, 10000));
			}
		}
	};

	const fluctuateCounter = (counter) => {
		const currentValue = +counter.innerText;
		const randomChange = Math.floor(Math.random() * 21) - 10; // Rango de -10 a +10
		const newValue = currentValue + randomChange;

		// Inicia la animación hacia el nuevo valor fluctuado
		animateCounter(counter, newValue);

		// Vuelve a llamar a la función después de un tiempo aleatorio
		setTimeout(() => fluctuateCounter(counter), randomTime(5000, 10000));
	};

	const randomTime = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	};

	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				const value = +entry.target.getAttribute("numberTo");
				animateCounter(entry.target, value);
			}
		});
	};

	const observer = new IntersectionObserver(handleIntersection, {
		root: null, // Usa el viewport como raíz
		threshold: 0.5, // Se activa cuando al menos el 50% del elemento es visible
	});

	counters.forEach((counter) => {
		observer.observe(counter);
	});
};

export { counterJS };
