export const modalsCustom = () => {
    const buttons = document.querySelectorAll('.btn-proximamente');
    const modalProximamente = document.querySelector('.modal-proximamente');
    const modalProximamenteLink = document.querySelector('.modal-proximamente-link');
    const modalProximamenteClose = document.querySelector('.btn-close-proximamente');
    const modalForm = document.querySelector('.modal-form');
    const modalFormClose = document.querySelector('.btn-close-form');
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const navBar = document.querySelector('#site-navbar');
    const response = document.querySelector('.wpcf7-response-output');
    
    function showModal(modal) {
        modal.classList.add('show');
        html.classList.add('overflow-modal-hidden');
        navBar.classList.add('padding-modal');
        body.classList.remove('overflow-x-hidden');
    }
    
    // Función para ocultar un modal
    function hideModal(modal) {
        modal.classList.remove('show');
        html.classList.remove('overflow-modal-hidden');
        navBar.classList.remove('padding-modal');
        body.classList.add('overflow-x-hidden');
    }

    buttons.forEach(button => {
        button.addEventListener('click', () => {
            showModal(modalProximamente);
        });
    });

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        showModal(modalForm);
        response.classList.add('d-none');

    }, false );

    modalProximamenteLink.addEventListener('click', () => {
        hideModal(modalProximamente);
    });

    modalProximamenteClose.addEventListener('click', () => {
        hideModal(modalProximamente);
    });

    modalProximamente.addEventListener('click', () => {
        hideModal(modalProximamente);
    });

    modalForm.addEventListener('click', () => {
        hideModal(modalForm);
    });

    modalFormClose.addEventListener('click', () => {
        hideModal(modalForm);
    });
    
}
