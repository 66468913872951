export const menuBtnFn = () => {
    const menuBtn = document.querySelector(".menu-btn");
    const menuMobile = document.querySelector(".menu-mobile");
    const menuLinks = document.querySelectorAll(".menu-mobile a");
    
    // Función para alternar la clase "open"
    function toggleMenu() {
        menuBtn.classList.toggle("open");
        menuMobile.classList.toggle("open");
    }
    
    // Agregar el evento click al botón del menú
    menuBtn.addEventListener('click', toggleMenu);
    
    // Agregar el evento click a cada enlace del menú
    menuLinks.forEach(link => {
        link.addEventListener('click', () => {
            // Remover la clase "open" cuando se hace clic en un enlace
            menuBtn.classList.remove("open");
            menuMobile.classList.remove("open");
        });
    });
}
