export const floatingAnimate = () => {
    function initAnimation(containerSelector, imageSelector) {
        const container = document.querySelector(containerSelector);
        const images = container.querySelectorAll(`[class^='${imageSelector}']`);
    
        // Genera un factor de movimiento aleatorio para cada imagen, algunos negativos para el efecto de ola
        const movementFactors = Array.from(images).map(() => ({
        x: (Math.random() * 0.1 + 0.02) * (Math.random() > 0.5 ? 1 : -1), // Valores entre -0.1 y 0.1
        y: (Math.random() * 0.1 + 0.02) * (Math.random() > 0.5 ? 1 : -1), // Valores entre -0.1 y 0.1
        }));
    
        let mouseX = 0;
        let mouseY = 0;
        let isHovering = false;
    
        // Actualiza la posición del mouse
        container.addEventListener("mousemove", (e) => {
        const rect = container.getBoundingClientRect();
        mouseX = e.clientX - rect.left;
        mouseY = e.clientY - rect.top;
        isHovering = true;
        });
    
        // Resetea el estado al salir del contenedor
        container.addEventListener("mouseleave", () => {
        isHovering = false;
        images.forEach((img) => {
            img.style.transition = 'transform 1s ease-in-out'; // Transición más lenta para el retorno
            img.style.transform = "translate(0, 0)";
        });
        });
    
        function animate() {
        if (isHovering) {
            images.forEach((img, index) => {
            const imgRect = img.getBoundingClientRect();
    
            // Usa los factores aleatorios de movimiento, algunos son negativos
            const offsetX = (mouseX - imgRect.left) * movementFactors[index].x;
            const offsetY = (mouseY - imgRect.top) * movementFactors[index].y;
    
            img.style.transition = 'none'; // Elimina la transición para un movimiento más fluido
            img.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
            });
        }
    
        requestAnimationFrame(animate); // Llama a la función en el siguiente frame
        }
    
        animate(); // Inicia la animación
    }
    
    // Inicia la animación para los dos contenedores por separado
    initAnimation(".profesional__mockup-desktop", "profesional__mockup__image-circle");
    initAnimation(".consultante__mockup-desktop", "consultante__mockup__image-circle");
}
